<template>
  <div class="aside-section">
    <div class="aside-header">
      <h2>Chat with Support</h2>
    </div>
    <div class="aside-chat-window" >
      <div style="overflow-x: scroll" class="chatting-screen">
        <div class="display-chat-screen" v-for="(row, key) in ticketChat.rows"
            :key="key" >
          <div v-if =" $auth.user().id===row.sender_id" class="display-chat-sent">
            <div class="chat-message-box" >
              <div class="chat-who-img">
                <img
                  src="https://image.ibb.co/cVTEsG/eAcademy.png"
                  alt= ""
                />

                <!-- <img
                 :src=user.profile_image
                  alt="profile image"
                /> -->
              </div>
              <div class="chat-msg-content">
                             
                <h4>{{ row.sender_name }} {{ row.sender_id }} {{ $auth.user().id }}</h4>
                <p>{{ row.message }}</p>
                <!-- {{ $auth.user().id }} -->
            <!-- {{ row.sender_id }}             -->
              </div>
            </div>
            <div class="chat-timing-box">
              <p>{{new Date(row.updated_at).toLocaleString("en-US") }}</p>
            </div>
          </div> 
           <div v-else class="display-chat-reply">
            <div class="message-box">
              <div class="chat-time">
                <p>{{new Date(row.updated_at).toLocaleString("en-US") }}</p>
              </div>
              <div class="message-section">
                <div class="message-content">
                  <h4>{{ row.sender_name }}</h4>

                  <p>{{ row.message }}</p>
                </div>

                <div class="img-content">
                  <!-- <img
                  src="https://eacademy.sgp1.cdn.digitaloceanspaces.com/eacademy/school/elU1iAip1hgV2mZo6HV1hxvnLeoVmCIt8oQQQwN7.png"
                  alt=""
                /> -->
                <img
                  src="https://image.ibb.co/cVTEsG/eAcademy.png"
                  alt= ""
                />
                </div>
              </div>
            </div>
          </div>     

                

         <!-- <div
            class="display-chat-sent"
            v-for="(row, key) in ticketChat.rows"
            :key="key"
          >
            <div class="chat-message-box">
              <div class="chat-who-img">                
                <img
                  src="https://eacademy.sgp1.cdn.digitaloceanspaces.com/eacademy/school/elU1iAip1hgV2mZo6HV1hxvnLeoVmCIt8oQQQwN7.png"
                  alr=""
                />
              </div>
              
              <div class="chat-msg-content">
                <h4>{{ row.sender_name }}</h4>
                <p>
                  {{ row.message }}
                </p>
              </div>
            </div>
            <div class="chat-timing-box">
              <p>{{ row.updated_at }}</p>
            </div>
          </div> -->
       

          <!-- <div class="display-chat-reply">
            <div class="message-box">
              <div class="chat-time">
                <p>Today at 9:04 AM</p>
              </div>
              <div class="message-section">
                <div class="message-content">
                  <h4>Riley Green</h4>

                  <p>Awesome work.</p>
                </div>

                <div class="img-content">
                  <img
                    src="https://toppng.com/uploads/preview/business-man-11530978835jxznfsrwvz.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="replay-message-screen">
        <div class="email-section">
          <div class="email-header">
            <a href="#">
              <span class="material-icons email-icon"> mail_outline </span>
            </a>
            <h3>Reply via email</h3>

            <a href="#">
              <span class="material-icons email-expand"> expand_more </span>
            </a>
          </div>

          <textarea
            id="fname"
            v-model="message"
            name="fname"
            class="reply-box"
            placeholder="Reply via email"
          >
          </textarea>
        </div>

        <v-btn
          class="reply-message-button"
          :disabled="message.length === 0"
          @click="saveMessage()"
          style="background-color: #78a300 !important"
          depressed
          color="primary"
        >
          Submit as New
        </v-btn>
      </div>
      <!-- </div>  -->
    </div>
  </div>
</template>

<script>
import { RestService, SERVICELIST } from "@/library/RestService";
const $restService = RestService(SERVICELIST.SUPPORT_TICKET);

export default {
	props: {
		ticket_id: {
			required: true,
		},
	},
	data() {
		return {
			ticketChat: [],
			drawer: false,
			message: "",
			user:{},
		};
	},
	mounted(){
		this.user = this.$auth.user();
	},
	watch: {
		ticket_id: function (value) {
			this.getMessage(value);
		},
	},
	methods: {
		saveMessage() {
			$restService
				.post(`/api/ticket-message`, {
					ticket_id: this.ticket_id,
					message: this.message,
				})
				.then(() => {
					this.message = "";
					this.getMessage(this.ticket_id);
				});
			console.log("Clicked on submit ticket");
		},
		getMessage(ticketId) {
			$restService
				.get(`/api/ticket-message?ticket_id=${ticketId}`)
				.then(({ data }) => {
					this.ticketChat = data;
				});
		},
	},
};
</script>

<style lang="scss">
</style>
