import axios from 'axios'
import appConfig from './Config'
import auth from './Auth'
import ls from './../library/Storage'

const base = window.location.origin;

let isLiveServer = false;
let isDevServer = false;
let isLocalServer = false;

if (process.env.VUE_APP_CONNECTION !== 'QA' && process.env.VUE_APP_CONNECTION !== 'LIVE' && process.env.VUE_APP_CONNECTION !== 'DEV') {
	isLiveServer = true;
} else if(process.env.VUE_APP_CONNECTION==='QA' || base === 'https://eacademy.sahakaryatech.com') {
	isDevServer = true;	
} else{
	isLocalServer = true;
}

export const SERVICELIST = {
	LIBRARY: 'LIBRARY',
	TRANSIT: 'TRANSIT',
	REPORTING: 'REPORTING',
	MISCELLANEOUS: 'MISCELLANEOUS',
	TRANSPORTATION: 'TRANSPORTATION',
	BILLING: 'BILLING',
	ACCOUNT: 'ACCOUNT',
	ACADEMIC: 'ACADEMIC',
	EVENT: 'EVENT',
	LIVE_CLASS: 'LIVE CLASS',
	SUPPORT_TICKET: 'SUPPORT TICKET',       
};


const serviceUrls = {
	'SUPPORT TICKET': isDevServer ? 'https://support.sahakaryatech.com' : (isLiveServer ? 'https://support.eacademynepal.com' : 'http://localhost:3131'),
	'TRANSIT': isDevServer ? 'https://transit.sahakaryatech.com' : (isLiveServer ? 'https://transit.eacademynepal.com' : 'http://transit.test'),
	'ACCOUNT': isDevServer ? 'https://account.sahakaryatech.com' : (isLiveServer ? 'https://account.eacademynepal.com' : 'http://account.test'),
	'MISCELLANEOUS': isDevServer ? 'https://miscellaneous.sahakaryatech.com' : (isLiveServer ? 'https://miscellaneous.eacademynepal.com' : 'http://miscellaneous.test'),
	'BILLING': isDevServer ? 'https://billing.sahakaryatech.com' : (isLiveServer ? 'https://billing.eacademynepal.com' : 'http://billing.test'),
	'ACADEMIC': isDevServer ? 'https://academic.sahakaryatech.com' : (isLiveServer ? 'https://academic.eacademynepal.com' : 'http://academic.test'),
	'EVENT': isDevServer ? 'https://event.sahakaryatech.com' : (isLiveServer ? 'https://event.eacademynepal.com' : 'http://event.test'),
	'REPORTING': isDevServer ? 'https://reporting.sahakaryatech.com' : (isLiveServer ? 'https://reporting.eacademynepal.com' : 'http://reporting.test'),
	'TRANSPORTATION': isDevServer ? 'https://transport.sahakaryatech.com' : (isLiveServer ? 'https://transport.eacademynepal.com' : 'http://transport.test'),
	'LIBRARY': isDevServer ? 'https://library.sahakaryatech.com' : (isLiveServer ? 'https://library.eacademynepal.com' : 'http://library.test')
}



export const RestService = (serviceType = SERVICELIST.TRANSIT) => {
	const subscribedServices = auth.company('services');
	const isSubscribed = subscribedServices.find(res => res.name === serviceType);

	if(!isSubscribed) throw Error(`Not subscribed to this service ${serviceType}`);
	if(!isSubscribed.token) throw Error(`Subscribed does not have token setup ${serviceType}`);

	const apiBaseUrl = serviceUrls[serviceType];

	let instance = axios.create({
		baseURL:  apiBaseUrl,//serviceType === SERVICELIST.SUPPORT_TICKET ? 'http://localhost:3131' : appConfig.BASE_URL,
		withCredentials: false,
		onUploadProgress: function (progressEvent) {
		// TRACK UPLOAD PROGRESS
		},
		onDownloadProgress: function (progressEvent) {
		// TRACK DOWNLOAD PROGRESS
		},
	});

	instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

	/**
     * Request Interceptor
    */
	const authServiceCommunicationToken = ls.get('_comm_tk');
	instance.interceptors.request.use(config => {
		if (authServiceCommunicationToken != null && authServiceCommunicationToken !== 'null') config.headers.common.Authorization = authServiceCommunicationToken;
		config.headers.common.ServiceToken = isSubscribed.token;
		if (window.localStorage.batch) {
			const batch = JSON.parse(window.localStorage.batch);
			config.headers.common.batch = batch.id;
		}

		config.headers.common.domain = location.host === 'system.axisacademyedu.com' ? 'system.eacademynepal.com' : location.host;

		if (appConfig.APP_ENV === 'QA') {
			config.headers.common.domain = 'eacademy.sahakaryatech.com';
		}
		if (location.host === "lms.iot-nepal.com") {
			config.headers.common.domain = 'system.iot-nepal.com';
		}
		if (appConfig.APP_ENV !== 'QA' && appConfig.APP_ENV !== 'LIVE' && appConfig.APP_ENV !== 'DEV') {
			config.headers.common.domain = appConfig.APP_ENV;
		}


		let role = window.localStorage._role;
		if (role) {
			config.headers.common.Role = role;
		}
		if (role === 'accountant') {
			if (window.localStorage._currentFiscalYear) {
				const fiscal_year = JSON.parse(window.localStorage._currentFiscalYear);
				config.headers.common.FiscalYear = fiscal_year.id;
			}
		}
		return config
	}, error => {
		return Promise.reject(error);
	});



	/**
 * Response Interceptor
 */
	instance.interceptors.response.use(response => {
		return response;
	}, error => {
		if (error.response) {
			if (error.response.status === appConfig.rest.UNAUTHORIZED) {
				auth.clear();
				window.location.replace('/login');
			}
		}
		return Promise.reject(error);
	});

	return instance;
};





